import { Component } from '@angular/core';

@Component({
  selector: 'app-section-dsp',
  templateUrl: './section-dsp.component.html',
  styleUrls: ['./section-dsp.component.scss']
})
export class SectionDspComponent {

  constructor() { }
}
