/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';

import { catchError, last, map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, OperatorFunction, throwError as _throw } from 'rxjs';

import { NotificationComponent } from '@shared/components';

import { HttpUtils } from './../../class/http-utils.class';
import {
  Company,
  CompanyResponse,
  CompanyResponseSingle,
  DSP,
  DSPResponseSingle,
  DSPUpdateResponse,
  StatisticResponse,
  SSP,
  SSPResponseSingle,
  SSPUpdateResponse,
  UserListResponse,
  Partner,
  ApiLinkTestResponse,
  PartnerSettings,
  SellersJsonSettings,
  MismatchesModel,
  PixalateSettings,
  PreBidItem,
  DroppedRequestsData,
  FiltersData,
  DroppedRequestResponse,
  PaginationStateDropped,
  ListTypeApiEndpointsMain,
  ListTypeMain,
  ListTypeApiEndpointsDSP,
  ListTypeDSP,
  ListTypeParamMain,
  RubiconSettings,
  ResponseForCompaniesMainTable,
  ResponseForEndpointsMainTable,
  PixalateResponse,
  BillingResponse,
  PixalateMacros,
  DashboardFilterResponse,
  FilterListInterface as IFilterList,
} from '../../interfaces';
import { AuthService } from '../auth';
import { AppOptionsService } from '../app-options';
import { NotificationService } from '../notification';
import { ErrorsHandlingService } from '../errors-handling';

@Injectable({
  providedIn: 'root',
})
export class DataManagerService {

  private apiURL;
  private companiesSubject = new BehaviorSubject<{ ssps: Company[], dsps: Company[], all: Company[] }>({ ssps: [], dsps: [], all: [] });
  companies = this.companiesSubject.asObservable();
  tokenRefreshAttempts = 0;
  apiLinkErrors: string[] = [];
  filterState: {
    sspId: number[],
    dspId?: number[],
    from: any,
    to: any,
    groupByDate: string,
    selectedEndpointSSP: number[],
    selectedEndpointDSP?: number[],
  } = null;

  filterStateSubject = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private options: AppOptionsService,
    private notificationService: NotificationService,
    private notificationComponent: NotificationComponent,
    private readonly errorsHandlingService: ErrorsHandlingService,
  ) {
    this.apiURL = this.options.getApiUrl();
  }

  refreshCompanies() {
    this.getCompanies()
      .subscribe(
        (resp: CompanyResponse) => {
          if (resp.status === 'success') {
            this.companiesSubject.next(this.filterCompanies(resp.data));
          }
        },
        (error) => {
          this.errorsHandlingService.handleError(error);
        },
      );
  }

  getFilters(filtersArray: { filters: string[] }): Observable<any> {
    return this.http.post(`${this.apiURL}/filter_values`, filtersArray)
      .pipe(catchError(this.processError));
  }

  public getEndpointsForDropdowns(): Observable<IFilterList.EndpointsData> {
    const params = new HttpParams().append('without_deleted', '1');

    return this.http.get<IFilterList.EndpointsData>(`${this.apiURL}/endpoints`, { params: params })
      .pipe(map(res => {
        res.dsp = res.dsp.map(item => {
          return { checked: false, tooltip: item.name, label: item.name, value: item.id, ...item };
        });

        res.ssp = res.ssp.map(item => {
          return { checked: false, tooltip: item.name, label: item.name, value: item.id, ...item };
        });

        return res;
      }),
        catchError(this.processError));
  }

  public getCompaniesForDropdowns(): Observable<IFilterList.CompaniesDataRes> {
    const params = new HttpParams().append('without_deleted', true);

    return this.http.get<IFilterList.CompaniesDataRes>(`${this.apiURL}/endpoints/grouped-by-company`, { params: params })
      .pipe(map(res => {
        res.ssp = res.ssp.map(company => {
          company.endpoints = company.endpoints.map(endpoint => {
            return { checked: false, disabled: false, label: endpoint.name, value: endpoint.id, ...endpoint };
          });
          return { checked: false, label: company.name, value: company.id, indeterminate: false, ...company };
        });

        res.dsp = res.dsp.map(company => {
          company.endpoints = company.endpoints.map(endpoint => {
            return { checked: false, disabled: false, label: endpoint.name, value: endpoint.id, ...endpoint };
          });
          return { checked: false, label: company.name, value: company.id, indeterminate: false, ...company };
        });

        return res;
      }),
        catchError(this.processError));
  }

  getDroppedFilters(): Observable<FiltersData> {
    return this.http.get<FiltersData>(`${this.apiURL}/filters_config`)
      .pipe(catchError(this.processError));
  }

  refreshCompaniesList() {
    return this.http.get(`${this.apiURL}/company/list`)
      .pipe(catchError(this.processError))
      .subscribe(
        (resp: CompanyResponse) => {
          if (resp.status === 'success') {
            this.companiesSubject.next(this.filterCompanies(resp.data));
          }
        },
        (error) => {
          this.errorsHandlingService.handleError(error);
        },
      );
  }

  toggleEndpointsStatus(id: number, active: number, endpointsType: string): Observable<any> {
    return this.http.put(
      `${this.apiURL}/company/${id}`,
      JSON.stringify({ active, endpointsType }),
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
      },
    )
      .pipe(catchError(this.processError));
  }

  updateSSPById(id: number, data: any): Observable<any> {
    return this.http.patch(
      `${this.apiURL}/ssp/${id}`,
      JSON.stringify(data),
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
      },
    )
      .pipe(catchError(this.processError));
  }

  updateDSPById(id: number, data: any): Observable<any> {
    return this.http.patch(
      `${this.apiURL}/dsp/${id}`,
      JSON.stringify(data),
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
      },
    )
      .pipe(catchError(this.processError));
  }

  getCompaniesNames(filter = null): Observable<CompanyResponse> {
    const requestParams = HttpUtils.getRequestParams({ ...filter as unknown as object });

    return this.http.get<CompanyResponse>(`${this.apiURL}/company/names`, { params: requestParams })
      .pipe(catchError(this.processError));
  }

  getCompaniesWithSummary(side: string, filter: {
    activity: string;
    currentPage: number;
    filters: {
      ad_format: any[];
      region: any[];
      integration_type: any[];
      traffic_type: any[];
    };
    search: string;
    sortBy: string;
    sortOrder: string;
  }): Observable<ResponseForCompaniesMainTable> {
    return this.http.post<ResponseForCompaniesMainTable>(`${this.apiURL}/company/summary-endpoints/${side}`, filter)
      .pipe(catchError(this.processError));
  }

  getDSP(id: number): Observable<DSPResponseSingle> {
    return this.http.get<DSPResponseSingle>(`${this.apiURL}/dsp/${id}`)
      .pipe(catchError(this.processError));
  }

  getSSP(id: number): Observable<SSPResponseSingle> {
    return this.http.get<SSPResponseSingle>(`${this.apiURL}/ssp/${id}`)
      .pipe(catchError(this.processError));
  }

  getSSPsFromMemory(): SSP[] {
    const data = this.companiesSubject.getValue();
    let ssps: SSP[] = [];
    data.ssps.forEach((c: Company) => {
      ssps = ssps.concat(c.ssp_settings);
    });
    return ssps;
  }

  getDSPsFromMemory() {
    const data = this.companiesSubject.getValue();
    let dsps: DSP[] = [];
    data.dsps.forEach((c: Company) => {
      dsps = dsps.concat(c.dsp_settings);
    });
    return dsps;
  }

  createNewCompany(data: any): Observable<CompanyResponseSingle> {
    return this.http.post<CompanyResponseSingle>(
      `${this.apiURL}/company`,
      JSON.stringify(data),
      { headers: new HttpHeaders().set('Content-Type', 'application/json') },
    )
      .pipe(catchError(this.processError));
  }

  createNewSSP(data: any): Observable<SSPUpdateResponse> {
    const post = { ...data };

    return this.http.post<SSPUpdateResponse>(
      `${this.apiURL}/ssp`,
      JSON.stringify(post),
      { headers: new HttpHeaders().set('Content-Type', 'application/json') },
    )
      .pipe(catchError(this.processError));
  }

  createNewDSP(data: {
    [key: string]: any;
  }): Observable<DSPUpdateResponse> {
    return this.http.post<DSPUpdateResponse>(`${this.apiURL}/dsp`, JSON.stringify(data),
      { headers: new HttpHeaders().set('Content-Type', 'application/json') },
    )
      .pipe(catchError(this.processError));
  }

  getStatistic(data: any): Observable<StatisticResponse> {

    const dataClone = JSON.parse(data);

    ['dspSpend', 'sspSpend', 'impressions', 'dspCpm', 'sspCpm'].map(fl => {
      const i = dataClone.groupBy.indexOf(fl);
      if (i > -1) {
        dataClone.groupBy.splice(i, 1);
      }
    });
    return this.http.post<StatisticResponse>(`${this.apiURL}/statistic`, JSON.stringify(dataClone),
      { headers: new HttpHeaders().set('Content-Type', 'application/json') }).pipe(catchError(this.processError));
  }

  downloadAdsTxt(data: any): Observable<any> {
    return this.http.get(`${this.apiURL}/adstxt`, { params: data, responseType: 'blob' })
      .pipe(catchError(this.processError));
  }

  parseFile(file: File, listType = 'sites'): Observable<{
    count: number;
    items: string[];
    status: string;
  }> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post<{
      count: number;
      items: string[];
      status: string;
    }>(`${this.options.getApiUrl()}/parse/type/${listType}`, formData, {
      reportProgress: true,
    })
      .pipe(catchError(this.processError));
  }

  parseFileCsv(file: File): Observable<{ status: string; count: number; items: (string | number)[] }> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post<{ status: string; count: number; items: (string | number)[] }>(`${this.options.getApiUrl()}/parse/csv`, formData, {
      reportProgress: true
    })
      .pipe(catchError(this.processError));
  }

  downLoadList(endpointType: string, endpointId: string, listType: string): Observable<Blob> {
    const byListTypeApiEndpoints: ListTypeApiEndpointsMain = {
      'ssp': {
        'blocked-crids': 'crid/blocked',
        'blocked-domains': 'domains/blocked',
      },
      'dsp': {
        'bundles': 'bundles/allowed',
        'sites': 'sites/allowed',
        'publishers': 'publishers/allowed',
        'blocked-publishers': 'publishers/blocked',
        'blocked-bundles': 'bundles/blocked',
        'blocked-sites': 'sites/blocked',
        'blocked-ips': 'ips/blocked',

      },
    };

    const apiEnd = byListTypeApiEndpoints[endpointType as keyof ListTypeApiEndpointsMain][listType as keyof ListTypeMain];
    return this.http.get(`${this.apiURL}/${endpointType}/${endpointId}/${apiEnd}/download`, { responseType: 'blob' })
      .pipe(catchError(this.processError));
  }

  deleteFilterList(endpointType: any, endpointId: any, listType: any): Observable<{ status: string }> {
    return this.http.delete<{ status: string }>(`${this.apiURL}/${endpointType}/${endpointId}/${listType}`)
      .pipe(catchError(this.processError));
  }

  getListAllowedBlocked(endpointType: string, endpointId: string, listType: string): Observable<any> {
    const byListTypeApiEndpoints: ListTypeApiEndpointsDSP = {
      dsp: {
        bundles: 'bundles/allowed-blocked',
        sites: 'sites/allowed-blocked',
        publishers: 'publishers/allowed-blocked',
      },
    };

    const apiEnd = byListTypeApiEndpoints[endpointType as keyof ListTypeApiEndpointsDSP][listType as keyof ListTypeDSP];
    return this.http.get(`${this.apiURL}/${endpointType}/${endpointId}/${apiEnd}`)
      .pipe(catchError(this.processError));
  }

  setList(endpointType: string, endpointId: string, listType: string, list: Array<string>): Observable<{
    data: { allowedPublishersCount: number }
    status: string;
  }> {

    const byListTypeApiEndpoints: ListTypeApiEndpointsMain = {
      'ssp': {
        'blocked-crids': 'crid/blocked',
        'blocked-domains': 'domains/blocked',
      },
      'dsp': {
        'bundles': 'bundles/allowed',
        'sites': 'sites/allowed',
        'publishers': 'publishers/allowed',
        'blocked-publishers': 'publishers/blocked',
        'blocked-bundles': 'bundles/blocked',
        'blocked-sites': 'sites/blocked',
        'blocked-ips': 'ips/blocked',
      },
    };
    const byListTypeParam: ListTypeParamMain = {
      'ssp': {
        'blocked-crids': 'blockedCrids',
        'blocked-domains': 'blockedDomains',
      },
      'dsp': {
        'bundles': 'allowedBundles',
        'sites': 'allowedSites',
        'publishers': 'allowedPublishers',
        'blocked-publishers': 'blockedPublishers',
        'blocked-bundles': 'blockedBundles',
        'blocked-sites': 'blockedSites',
        'blocked-ips': 'blockedIps',
      },
    };


    const apiEnd = byListTypeApiEndpoints[endpointType][listType];

    const param = byListTypeParam[endpointType][listType];
    const data = {};
    data[param] = list.filter(i => i.toString().trim().length > 0);

    return this.http.post<{
      data: { allowedPublishersCount: number }
      status: string;
    }>(`${this.apiURL}/${endpointType}/${endpointId}/${apiEnd}`, data)
      .pipe(catchError(this.processError));
  }

  getBilling(data: {
    fetched: boolean;
    filter: {
      sspId: number[];
      dspId: number[];
    }
    from: string;
    groupBy: string[];
    revshare: boolean;
    revshare_value?: string;
    to: string;
    type: string;
  }): Observable<BillingResponse> {
    return this.http.post<BillingResponse>(`${this.apiURL}/billing`, data)
      .pipe(catchError(this.processError));

  }

  getUsers(): Observable<UserListResponse> {
    return this.http.get<UserListResponse>(`${this.options.getApiUrl()}/user`)
      .pipe(catchError(this.processError));
  }

  downloadBillingData(data: {
    fetched: boolean;
    filter: {
      sspId: number[];
      dspId: number[];
    }
    from: string;
    groupBy: string[];
    revshare: boolean;
    revshare_value?: string;
    to: string;
    type: string;
  }): Observable<any> {
    return this.http.post(`${this.apiURL}/billing/download`, data)
      .pipe(catchError(this.processError));
  }

  deleteUser(id: number): Observable<{ status: string }> {
    return this.http.delete<{ status: string }>(`${this.options.getApiUrl()}/user/${id}`)
      .pipe(catchError(this.processError));
  }

  getAllCompaniesByConnectionType(filter: { connectionType: number, settings: string }): Observable<any> {
    return this.http.post<any>(`${this.options.getApiUrl()}/company/connection-type`, filter)
      .pipe(catchError(this.processError));
  }

  updateCompany(company: Company): Observable<{ status: string, data: Company }> {
    return this.http.put<{ status: string, data: Company }>(`${this.options.getApiUrl()}/company/${company.id}`,
      JSON.stringify(company),
      { headers: new HttpHeaders().set('Content-Type', 'application/json') },
    )
      .pipe(catchError(this.processError));
  }

  deleteCompanyById(id: number): Observable<CompanyResponse> {
    return this.http.delete<CompanyResponse>(`${this.options.getApiUrl()}/company/${id}`)
      .pipe(catchError(this.processError));
  }

  getPartner(): any {
    return this.authService.partner;
  }

  setPartner(partner: Partner) {
    this.authService.partner = partner;
  }

  refreshPartnerData(): Observable<Partner> {
    return this.http.get<Partner>(`${this.options.getApiUrl()}/partners/-1`)
      .pipe(catchError(this.processError));
  }

  testApiLink(link: string): Promise<ApiLinkTestResponse> {
    return new Promise((resolve) => {
      this.http.post(`${this.options.getApiUrl()}/company/apilinktest`, { link: link })
        .pipe(catchError(this.processError))
        .subscribe(
          (resp: any) => {
            resolve(resp);
          },
          () => {
            const errorMessage = `Can not find mandatory date macro. Please, find date params in API Link and replace it \n with placeholder [%Y-m-d%],
              where Y-m-d - corresponding date format.`;
            this.showNotification('error', 'error', 'error', errorMessage, null);
          },
        );
    });
  }

  validateApiLinkUnique(link: string, side: string, endpointId: number): Observable<any> {
    return this.http.post(`${this.options.getApiUrl()}/endpoint/validate/${side}/unique`, { link: link, endpointId: endpointId })
      .pipe(catchError(this.processError));
  }

  validateApiLinkAsUrl(link: string): Observable<any> {
    return this.http.post(`${this.options.getApiUrl()}/endpoint/validate/url`, { link: link })
      .pipe(catchError(this.processError));
  }

  validateApiLinkContainsMacros(link: string): Observable<any> {
    return this.http.post(`${this.options.getApiUrl()}/endpoint/validate/macros`, { link: link })
      .pipe(catchError(this.processError));
  }

  validateApiLinkStatus200(link: string): Observable<any> {
    return this.http.post(`${this.options.getApiUrl()}/endpoint/validate/status200`, { link: link })
      .pipe(catchError(this.processError));
  }

  notifyAboutApiLink(link: string, side: string): Observable<{ status: string, message: string }> {
    return this.http.post<{ status: string, message: string }>(`${this.options.getApiUrl()}/endpoint/apilink/notify-support`,
      { link: link, side: side })
      .pipe(catchError(this.processError));
  }

  sendSuggestFeature(suggest: {
    title: string | Blob;
    description: string | Blob;
    screenshot: any;
  }): Observable<{ status: string, message: string }> {
    const formData: FormData = new FormData();
    formData.append('title', suggest.title);
    formData.append('description', suggest.description);

    if (suggest.screenshot) {
      formData.append('screenshot', suggest.screenshot, suggest.screenshot.name);
    } else {
      formData.append('screenshot', suggest.screenshot);
    }


    return this.http.post<{ status: string, message: string }>(`${this.options.getApiUrl()}/suggest-feature`, formData)
      .pipe(catchError(this.processError));
  }

  getEndpointsForCompany(side: string, filter: {
    search: string;
    sortBy: string;
    sortOrder: string;
    activity: string;
    currentPage: number;
    filters: {
      ad_format: string[];
      region: string[];
      integration_type: number[];
      traffic_type: string[];
    }
  }): Observable<ResponseForEndpointsMainTable> {
    return this.http.post<ResponseForEndpointsMainTable>(`${this.options.getApiUrl()}/company/endpoints/${side}`, filter)
      .pipe(catchError(this.processError));
  }

  getCompanyList(searchValue: string | number = ''): Observable<CompanyResponse> {
    return this.http.post<CompanyResponse>(`${this.options.getApiUrl()}/company/get-all`, { search: searchValue })
      .pipe(catchError(this.processError));
  }

  getDashboardFilters(): Observable<DashboardFilterResponse> {
    return this.http.get<DashboardFilterResponse>(`${this.options.getApiUrl()}/dashboard`)
      .pipe(catchError(this.processError));
  }

  savePartnerSettings(settings: PartnerSettings): Observable<PartnerSettings> {
    const loggedPartner = this.getPartner();
    return this.http.patch<PartnerSettings>(`${this.options.getApiUrl()}/partners/` + loggedPartner.id, settings)
      .pipe(catchError(this.processError));
  }

  updateAdsTxt(data: { adstxtTagId: string }): Observable<{ message: string[] }> {
    return this.http.post<{ message: string[] }>(`${this.options.getApiUrl()}/adstxt`, data)
      .pipe(catchError(this.processError));
  }

  updateTcfVendorId(data: { tcf_vendor_id: number }): Observable<{ message: string[] }> {
    return this.http.post<{ message: string[] }>(`${this.options.getApiUrl()}/tcf`, data)
      .pipe(catchError(this.processError));
  }

  updateColumn(data: { column: string, value: boolean }): Observable<{ message: string }> {
    return this.http.patch<{ message: string }>(`${this.options.getApiUrl()}/partner/column`, data)
      .pipe(catchError(this.processError));
  }

  saveSellersJsonSettings(settings: SellersJsonSettings): Observable<SellersJsonSettings> {
    return this.http.post<SellersJsonSettings>(`${this.options.getApiUrl()}/sellers/update`, settings)
      .pipe(catchError(this.processError));
  }

  saveNewPartnerSettings(params: { [key: string]: number | boolean }): Observable<{ data: Partner, success: boolean }> {
    return this.http.post<{ data: Partner, success: boolean }>(`${this.apiURL}/partner/settings/save`, params).pipe(catchError(this.processError));
  }

  updatePartnerSettings(params: { [key: string]: any }, partnerId: number): Observable<PartnerSettings> {
    return this.http.patch<PartnerSettings>(`${this.options.getApiUrl()}/partners/` + partnerId, params)
      .pipe(catchError(this.processError));
  }

  downloadScannerReport(scannerType: any): Observable<any> {
    return this.http.request(
      'POST',
      `${this.options.getApiUrl()}/settings/scanner-report/${scannerType}`,
      { reportProgress: true, responseType: 'blob', headers: { 'Content-Type': 'application/json' }, observe: 'response' },
    )
      .pipe(
        map(event => this.getDownloadEventMessage(event)),
        tap(message => {
          this.handleMessage(message);
        }),
        last(),
        catchError(this.processError),
      );
  }

  downloadSellerJson(): Observable<any> {
    return this.http.request(
      'POST',
      `${this.options.getApiUrl()}/settings/sellers.json`,
      { reportProgress: true, responseType: 'blob', headers: { 'Content-Type': 'application/json' }, observe: 'response' },
    )
      .pipe(
        map(event => this.getDownloadEventMessage(event)),
        tap(message => {
          this.handleMessage(message);
        }),
        last(),
        catchError(this.processError),
      );
  }

  downloadTrafficLoggerReport(uuid: any, aggrigate: number): Observable<any> {
    return this.http.request(
      'POST',
      `${this.options.getApiUrl()}/traffic-logger/download/${uuid}`,
      {
        body: JSON.stringify({ aggrigate: aggrigate }), reportProgress: true, responseType: 'blob',
        headers: { 'Content-Type': 'application/json' }, observe: 'response',
      },
    )
      .pipe(
        map(event => this.getDownloadEventMessage(event)),
        tap(message => {
          this.handleMessage(message);
        }),
        last(),
        catchError(this.processError),
      );
  }

  getTrafficLoggerExample(uuid: string, key: string): Observable<any> {
    return this.http.get(
      `${this.options.getApiUrl()}/traffic-logger/example`,
      { params: { key: key, uuid: uuid } },
    )
      .pipe(catchError(this.processError));
  }

  getBidExample(side: string, type: string, id: number): Observable<any> {
    return this.http.get<any>(`${this.apiURL}/${side}/${id}/${type}/example`).pipe(catchError(this.processError));
  }

  saveImpressionStat(data: any): Observable<any> {
    return this.http.patch<PartnerSettings>(`${this.options.getApiUrl()}/billing`, data)
      .pipe(catchError(this.processError));
  }

  getMismatches(type: string): Observable<MismatchesModel[]> {
    return this.http.get<MismatchesModel[]>(`${this.options.getApiUrl()}/ssp/mismatches/${type}`)
      .pipe(catchError(this.processError));
  }

  downloadMismatch(type: string, element: MismatchesModel): Observable<{ url: string }> {
    const data = {
      'mismatch[dt]': element.dt,
      'mismatch[ssp_ids]': element.ssp_ids,
    };

    const requestParams = HttpUtils.getRequestParams({ ...data });

    return this.http.get<{ url: string }>(`${this.options.getApiUrl()}/ssp/mismatches/${type}/download`, { params: requestParams })
      .pipe(catchError(this.processError));
  }

  reloadApiLinkData(filter, endpointId): Observable<any> {
    const data = {
      'side': filter.type,
      'endpointId': endpointId,
      'from': filter.from,
      'to': filter.to,
      'sspId': filter.filter.sspId,
      'dspId': filter.filter.dspId,
    };

    return this.http.post(
      `${this.options.getApiUrl()}/company/frontendapilinktest`,
      JSON.stringify(data),
      { headers: new HttpHeaders().set('Content-Type', 'application/json') },
    )
      .pipe(catchError(this.processError));
  }

  refreshAllAPILinksData(filter: {
    fetched: boolean;
    filter: {
      sspId: number[];
      dspId: number[];
    };
    from: string;
    groupBy: string[];
    revshare: boolean;
    revshare_value: string;
    side: string;
    to: string;
    type: string;
  }): Observable<any> {
    return this.http.post(`${this.options.getApiUrl()}/company/refresh-apilinks`, filter,
      { headers: new HttpHeaders().set('Content-Type', 'application/json') })
      .pipe(catchError(this.processError));
  }

  deleteEndpoint(endpointId: any, type: any): Observable<any> {
    const url = `${this.options.getApiUrl()}/${type}/${endpointId}`;
    return this.http.delete(url)
      .pipe(catchError(this.processError));
  }

  getPartnerQpsStatistic(): Observable<{
    partperQps: {
      incomingReal: number,
      outgoingReal: number,
      incomingStandart: number,
      outgoingStandart: number
    }, sspQps: any[], dspQps: any[]
  }> {
    return this.http.post<{
      partperQps: {
        incomingReal: number,
        outgoingReal: number,
        incomingStandart: number,
        outgoingStandart: number
      }, sspQps: any[], dspQps: any[]
    }>(
      `${this.options.getApiUrl()}/partner/qps/limits`,
      null,
      { headers: new HttpHeaders().set('Content-Type', 'application/json') })
      .pipe(catchError(this.processError));
  }

  getRevenue(): Observable<{
    revenue: {
      trafficType: { today: any[], totalsToday: any, yesterday: any[], totalsYesterday: any },
      adFormat: { today: any[], totalsToday: any, yesterday: any[], totalsYesterday: any }
    }
  }> {
    return this.http.post<{
      revenue: {
        trafficType: { today: any[], totalsToday: any, yesterday: any[], totalsYesterday: any },
        adFormat: { today: any[], totalsToday: any, yesterday: any[], totalsYesterday: any }
      }
    }>(
      `${this.options.getApiUrl()}/revenue`,
      null,
      { headers: new HttpHeaders().set('Content-Type', 'application/json') })
      .pipe(catchError(this.processError));
  }

  sendInfoAboutBrowser(data: {
    userAgent: string;
    width: number;
    height: number;
  }): Observable<{ success: boolean }> {
    return this.http.post<{ success: boolean }>(`${this.options.getApiUrl()}/info-browser`, data)
      .pipe(catchError(this.processError));
  }

  verifyTraffic(autologger: any, errFunc: OperatorFunction<any, unknown>): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.http.post<any>(
        `${this.options.getApiUrl()}/traffic-logger`,
        autologger,
        { headers: new HttpHeaders().set('Content-Type', 'application/json') })
        .pipe(errFunc).subscribe(resp => {
          resolve(resp);
        }, error => {
          this.errorsHandlingService.handleError(error);
          reject(error);
        });
    });
  }

  getSspForAutoLogger(filter: { [key: string]: number } = { active: 1 }): Observable<any> {
    return this.http.get(`${this.options.getApiUrl()}/ssp`, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      params: new HttpParams().set(Object.keys(filter)[0], String(Object.values(filter)[0])),
    })
      .pipe(catchError(this.processError));
  }

  getPartnerQpsOverLimit(): Observable<{ isOverQps: boolean }> {
    return this.http.post<{ isOverQps: boolean }>(
      `${this.options.getApiUrl()}/partner/isoverqps`,
      null,
      { headers: new HttpHeaders().set('Content-Type', 'application/json') },
    ).pipe(catchError(this.processError));
  }

  getSeatAndTokenForSspEndpoint(): Observable<any> {
    return this.http.post(`${this.options.getApiUrl()}/ssp/endpoint/seat-and-token`, null,
      { headers: new HttpHeaders().set('Content-Type', 'application/json') },
    ).pipe(catchError(this.processError));
  }

  getPixalateMacros(): Observable<PixalateMacros> {
    return this.http.get<PixalateMacros>(`${this.options.getApiUrl()}/settings/pixalate/macros`)
      .pipe(catchError(this.processError));
  }

  storeRubicon(id: number, body: RubiconSettings): Observable<RubiconSettings | any> {
    return this.http.post(`${this.options.getApiUrl()}/settings/rubicon/${id}`, body)
      .pipe(catchError(this.processError));
  }

  getPixalateById(id: number): Observable<PixalateResponse> {
    return this.http.get<PixalateResponse>(`${this.options.getApiUrl()}/settings/pixalate/${id}`)
      .pipe(catchError(this.processError));
  }

  updatePixalate(id: number, body: PixalateSettings): Observable<PixalateSettings | any> {
    return this.http.put(`${this.options.getApiUrl()}/settings/pixalate/${id}`, body)
      .pipe(catchError(this.processError));
  }

  createPixalate(body: PixalateSettings): Observable<PixalateSettings | any> {
    return this.http.post(`${this.options.getApiUrl()}/settings/pixalate`, body)
      .pipe(catchError(this.processError));
  }

  downLoadMainInfo(side, filter): Observable<any> {
    const activeEndpoint = this.options.getOptionsMainActivity();
    const activeOptions = this.options.getOptions();
    const columns = [];

    for (let i = 0; i < activeOptions[side].length; i++) {
      if (activeOptions[side][i].active) {
        columns.push(activeOptions[side][i].attr);
      }
    }
    filter.columns = columns;

    return this.http.request(
      'POST',
      `${this.options.getApiUrl()}/company/download-info/${side}?activeEndpoint=${activeEndpoint[side + 'Selected']}`,
      {
        body: JSON.stringify(filter),
        reportProgress: true,
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json'
        },
        observe: 'response'
      }
    ).pipe(
      map(event => this.getDownloadEventMessage(event)),
      tap(message => {
        this.handleMessage(message);
      }),
      last(),
      catchError(this.processError)
    );
  }

  checkTechnicalWorks(): Observable<{ messageType: string; useShowAlert: string; }> {
    return this.http.get<{ messageType: string; useShowAlert: string; }>(
      `${this.options.getApiUrl()}/check-technical-works`,
      { headers: new HttpHeaders().set('Content-Type', 'application/json') },
    ).pipe(catchError(this.processError));
  }

  checkSSLExpiry(): Observable<{ data: { days: number; message: string; } }> {
    return this.http.get<{ data: { days: number; message: string; } }>(`${this.options.getApiUrl()}/alerts/ssl-expiry`);
  }

  checkAlerts(): Observable<any> {
    return this.http.get(
      `${this.options.getApiUrl()}/check-alerts`,
      { headers: new HttpHeaders().set('Content-Type', 'application/json') },
    ).pipe(catchError(this.processError));
  }

  generateCookieSyncUrl(): Observable<{ urlDomain: string, urlEndpoint: string, urlQuery: string }> {
    return this.http.get<{ urlDomain: string, urlEndpoint: string, urlQuery: string }>(`${this.options.getApiUrl()}/cookie-sync/url`,
      { headers: new HttpHeaders().set('Content-Type', 'application/json') }).pipe(catchError(this.processError));
  }

  saveSSPCookieSync(cookieSync: any): Observable<any> {
    return this.http.post(`${this.options.getApiUrl()}/cookie-sync`, cookieSync,
      { headers: new HttpHeaders().set('Content-Type', 'application/json') })
      .pipe(catchError(this.processError));
  }

  saveDspCookieSync(data: any): Observable<any> {
    return this.http.post(`${this.options.getApiUrl()}/dsp-cookie-sync`, data, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    })
      .pipe(catchError(this.processError));
  }

  downLoadCookieSync(id: any): Observable<any> {
    return this.http.request(
      'POST',
      `${this.options.getApiUrl()}/cookie-sync/download/${id}`,
      { body: null, reportProgress: true, responseType: 'blob', headers: { 'Content-Type': 'application/json' }, observe: 'response' },
    ).pipe(
      map(event => this.getDownloadEventMessage(event)),
      tap(message => {
        this.handleMessage(message);
      }),
      last(),
      catchError(this.processError),
    );
  }

  droppedRequests(filter: {
    sspId: number[];
    dspId?: number[];
    from: any;
    to: any;
    perPage: number;
    pageIndex: number;
    overviewType: string;
    trafficType?: number[];
    groupByDate: string;
    country?: number[];
    adFormat?: number[];
    orderDirection: string;
    orderBy: string;
  }): Observable<PaginationStateDropped<DroppedRequestResponse[]>> {
    return this.http.post<PaginationStateDropped<DroppedRequestResponse[]>>
      (`${this.options.getApiUrl()}/statistic/dropped/requests_and_responses`, filter)
      .pipe(map(res => {
        Object.keys(res.rows).map(rowKey => {
          res.rows[+rowKey].reasons.map((item) => {
            item.tooltip = item.failure_reason;
            return item;
          });
          return res.rows[+rowKey];
        });
        return res;
      }),
        catchError(this.processError),
      );
  }

  downloadDroppedRequests(data: { [key: string]: any }): Observable<any> {
    return this.http.post(`${this.apiURL}/statistic/dropped/requests_and_responses/download`, data)
      .pipe(catchError(this.processError));
  }

  getEndpoints(): Observable<any> {
    return this.http.get<any>(`${this.options.getApiUrl()}/endpoints`)
      .pipe(catchError(this.processError));
  }

  getEndpointsForDroppedStatistic(filter: {
    from: string;
    to: string;
    overviewType: string;
    groupByDate: string;
  }): Observable<DroppedRequestsData> {
    return this.http.get<DroppedRequestsData>(`${this.options.getApiUrl()}/active-endpoints`, { params: filter })
      .pipe(map(res => {
        res.dsp.map(item => {
          item.checked = false;
          if (item.name.length > 35) {
            item.tooltip = item.name;
          }
          return item;
        },
          res.ssp.map(item => {
            item.checked = false;
            if (item.name.length > 35) {
              item.tooltip = item.name;
            }
            return item;
          }),
        );

        return res;
      }),
        catchError(this.processError),
      );
  }

  saveForensiqScannerEndpoints(sspEndpoints: any): Observable<any> {
    return this.http.post(
      `${this.options.getApiUrl()}/scanners/forensiq/endpoints`,
      { ssp: sspEndpoints },
      { headers: new HttpHeaders().set('Content-Type', 'application/json') })
      .pipe(catchError(this.processError));
  }

  saveForensiqScanner(scanner: any): Observable<any> {
    return this.http.post(
      `${this.options.getApiUrl()}/scanners/forensiq`,
      scanner,
      { headers: new HttpHeaders().set('Content-Type', 'application/json') })
      .pipe(catchError(this.processError));
  }

  savePMPrebidScanner(scanner: any): Observable<any> {
    return this.http.post(`${this.options.getApiUrl()}/scanners/pm-prebid`, scanner,
      { headers: new HttpHeaders().set('Content-Type', 'application/json') })
      .pipe(catchError(this.processError));
  }

  connectPMPrebid(data: any): Observable<any> {
    return this.http.post(`${this.options.getApiUrl()}/scanners/pm-prebid/connect`, data,
      { headers: new HttpHeaders().set('Content-Type', 'application/json') })
      .pipe(catchError(this.processError));
  }

  savePMPrebidEndpoints(ssp: any, dsp: any): Observable<any> {
    return this.http.post(`${this.options.getApiUrl()}/scanners/pm-prebid/endpoints`, { ssp: ssp, dsp: dsp },
      { headers: new HttpHeaders().set('Content-Type', 'application/json') })
      .pipe(catchError(this.processError));
  }

  savePMScanner(scanner: any): Observable<any> {
    return this.http.post(`${this.options.getApiUrl()}/scanners/pm`, scanner,
      { headers: new HttpHeaders().set('Content-Type', 'application/json') })
      .pipe(catchError(this.processError));
  }

  saveBotmanScanner(scanner: any): Observable<any> {
    return this.http.post(`${this.options.getApiUrl()}/scanners/botman`, scanner,
      { headers: new HttpHeaders().set('Content-Type', 'application/json') })
      .pipe(catchError(this.processError));
  }

  saveBotmanEndpoints(ssp: any, dsp: any): Observable<any> {
    return this.http.post(`${this.options.getApiUrl()}/scanners/botman/endpoints`,
      { ssp: ssp, dsp: dsp, },
      { headers: new HttpHeaders().set('Content-Type', 'application/json') })
      .pipe(catchError(this.processError));
  }

  savePMEndpoints(ssp: any, dsp: any): Observable<any> {
    return this.http.post(`${this.options.getApiUrl()}/scanners/pm/endpoints`,
      { ssp: ssp, dsp: dsp },
      { headers: new HttpHeaders().set('Content-Type', 'application/json') })
      .pipe(catchError(this.processError));
  }

  saveTMTScanner(scanner: any): Observable<any> {
    return this.http.post(`${this.options.getApiUrl()}/scanners/tmt`, scanner,
      { headers: new HttpHeaders().set('Content-Type', 'application/json') })
      .pipe(catchError(this.processError));
  }

  saveTMTEndpoints(ssp: any): Observable<any> {
    return this.http.post(`${this.options.getApiUrl()}/scanners/tmt/endpoints`, { ssp: ssp },
      { headers: new HttpHeaders().set('Content-Type', 'application/json') })
      .pipe(catchError(this.processError));
  }

  savePixalatePrebidScanner(scanner: any): Observable<any> {
    return this.http.post(`${this.options.getApiUrl()}/scanners/prebid-pixalate`, scanner,
      { headers: new HttpHeaders().set('Content-Type', 'application/json') })
      .pipe(catchError(this.processError));
  }

  savePixalatePrebidScannerEndpoints(dsp: any, ssp: any): Observable<any> {
    return this.http.post(`${this.options.getApiUrl()}/scanners/prebid-pixalate/endpoints`, { dsp: dsp, ssp: ssp },
      { headers: new HttpHeaders().set('Content-Type', 'application/json') })
      .pipe(catchError(this.processError));
  }

  savePixalateEndpoints(ssp, dsp): Observable<any> {
    return this.http.post(`${this.options.getApiUrl()}/scanners/pixalate/endpoints`, { ssp: ssp, dsp: dsp },
      { headers: new HttpHeaders().set('Content-Type', 'application/json') })
      .pipe(catchError(this.processError));
  }

  saveGeoEdgeScanner(scanner: any): Observable<any> {
    return this.http.post(`${this.options.getApiUrl()}/scanners/geoedge`, scanner,
      { headers: new HttpHeaders().set('Content-Type', 'application/json') })
      .pipe(catchError(this.processError));
  }

  saveGeoEdgeEndpoints(ssp: any): Observable<any> {
    return this.http.post(
      `${this.options.getApiUrl()}/scanners/geoedge/endpoints`,
      { ssp: ssp },
      { headers: new HttpHeaders().set('Content-Type', 'application/json') })
      .pipe(catchError(this.processError));
  }

  getCompaniesWithEndpoints(side = 'ssp', filter: { activity: number } = { activity: 1 }): Observable<any> {
    return this.http.post<any>(`${this.options.getApiUrl()}/scanners/endpoints/${side}`, filter)
      .pipe(catchError(this.processError));
  }

  getPartnerSettingsForScanners(): Observable<any> {
    const loggedPartner = this.getPartner();
    return this.http.post<any>(`${this.options.getApiUrl()}/scanners/partners/` + loggedPartner.id, null).pipe(catchError(this.processError));
  }

  getAdServers(): Observable<any> {
    return this.http.post(`${this.options.getApiUrl()}/ad-servers`, null, { headers: new HttpHeaders().set('Content-Type', 'application/json') })
      .pipe(catchError(this.processError));
  }

  getAdaptersByType(filter: { is_prebid: boolean, type: string }): Observable<PreBidItem[]> {
    return this.http.post<PreBidItem[]>(`${this.options.getApiUrl()}/adapters`, filter)
      .pipe(catchError(this.processError));
  }

  duplicateEndpoint(id: any, side: any): Observable<any> {
    return this.http.get(`${this.options.getApiUrl()}/company/endpoint-duplicate/${id}/${side}`)
      .pipe(catchError(this.processError));
  }

  verifyPixalateCredentials(credentials: { password: string; link: string; login: string }): Observable<any> {
    return this.http.post(`${this.options.getApiUrl()}/scanners/prebid-pixalate/verify`, credentials,
      { headers: new HttpHeaders().set('Content-Type', 'application/json') })
      .pipe(catchError(this.processError));
  }

  private processError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      return _throw({ code: 0, messages: ['Application crashed. Please try to refresh the page.'] });
    }
    // The backend returned an unsuccessful response code.
    // The response body may contain clues as to what went wrong,

    if (error.status === 400) {
      const messages = [];
      if (error.error.errors) {
        for (const i in error.error.errors) {
          messages.push(error.error.errors[i]);
        }
      }
      return _throw({ code: 400, messages });
    }

    if (error.status === 401) {
      return _throw({ code: 401, messages: ['Unauthorized. Please log in'] });
    }

    if (error.status === 403) {
      return _throw({ code: 403, messages: ['Action is not allowed'] });
    }

    if (error.status === 404) {
      return _throw({ code: 404, messages: ['Can\'t process the request. Requested URL not found.'] });
    }

    if (error.status === 422) {
      const errors = error.error.errors;
      const messages = errors ? Object.values(errors).map((item) => {
        if (typeof item === 'object') {
          const errorsArray = Object.values(item).flat().join('\r\n');
          return errorsArray;
        } else {
          return item;
        }
      }) : [];
      return _throw({ code: 422, messages, errors });
    }

    if (error.status === 500) {
      return _throw({ code: 500, messages: ['Internal server error'] });
    }

    if (error.status === 503) {
      return _throw({ code: 503, messages: ['Service Unavailable'] });
    }

    return _throw({ code: 0, messages: ['Application crashed. Please try to refresh the page.'] });
  }

  private bytesToSize(bytes: number): string {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) {
      return '0 Byte';
    }
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
  }

  private getDownloadEventMessage(event: HttpEvent<any>) {
    return event;
  }

  private handleMessage(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.Sent:
        this.showNotification('info', 'info', 'info', 'Requesting data...', null);
        break;
      case HttpEventType.ResponseHeader:
        this.showNotification('info', 'info', 'info', 'Requesting data...', null);
        break;
      case HttpEventType.DownloadProgress:
        this.showNotification('info', 'info', 'info', `${this.bytesToSize(event.loaded)} downloaded`, null);
        break;
    }
    return event;
  }

  private filterCompanies(companies: Company[]): { ssps: Company[], dsps: Company[], all: Company[] } {
    const ssps: Company[] = [];
    const dsps: Company[] = [];
    const all: Company[] = [];
    companies.forEach((c) => {
      if ((c.ssp_settings === undefined || c.dsp_settings === undefined)
        || (!c.ssp_settings && !c.dsp_settings)
      ) {
        return;
      }
      all.push(c);
      if (c.dsp_settings.length) {
        dsps.push(c);
      }
      if (c.ssp_settings.length) {
        ssps.push(c);
      }
    });
    return { ssps, dsps, all };

    return { ssps, dsps, all };
  }

  private getCompanies(filter = null): Observable<CompanyResponse> {
    const requestParams = HttpUtils.getRequestParams({ ...filter });

    return this.http.get<CompanyResponse>(`${this.apiURL}/company`, { params: requestParams })
      .pipe(catchError(this.processError));
  }

  private showNotification(
    iconType: string,
    color: string,
    notificationType: string,
    description: string,
    width: string,
  ): void {
    const data = {
      iconType,
      color,
      notificationType,
      description,
    };

    this.notificationComponent.data = data;
    this.notificationService.showTemplate({ nzData: data, nzStyle: { width: width ?? '385px' } });
  }
}
