import { Directive, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive({
  selector: '[endpointUrl]',
})
export class EndpointUrlDirective {
  @Input('appEndpointUrl') errName!: string;
  @Input('errorMessages') errMessages: any;
  constructor() { }

  validate(control: AbstractControl): { [key: string]: any } {
    // eslint-disable-next-line max-len
    const URL_REGEXP = /^((https?):\/\/)?([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2,}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,\[\]?'\\+&%}{$#=~_-]+))*$/mi;
    if (control.value && !URL_REGEXP.test(control.value)) {
      return { [this.errName]: { value: this.errMessages[this.errName] } };
    }
    return null as unknown as object;
  }

}
